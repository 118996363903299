import { createEventHook } from "@vueuse/core";

export const getPsAuthResponseFailed = () => new URLSearchParams(window.location.search).get("failed") === "true";
export const getPsAuthResponseMsg = () => new URLSearchParams(window.location.search).get("msg");

export const getPsNormalizedAuthApp = (authApp: string) => authApp?.replaceAll("-", "_")?.toUpperCase();

export const getPsAuthPath = (authApp: string) => `/node/authenticate?app=${getPsNormalizedAuthApp(authApp)}`;

const getAuthCheckPath = (authApp: string) => `${getPsAuthPath(authApp)}&action=check`;
const getAuthFeatureCheckPath = (authApp: string, featureKey: string) =>
    `${getAuthCheckPath(authApp)}&feature=${featureKey}`;
const getAuthLogoutPath = (authApp: string) => `${getPsAuthPath(authApp)}&logout=1`;

const getAuthCheckUrl = (authApp: string, protocol = "//", host = location.host) =>
    `${protocol}${host}${getAuthCheckPath(authApp)}`;
const getAuthLogoutUrl = (authApp: string, protocol = "//", host = location.host) =>
    `${protocol}${host}${getAuthLogoutPath(authApp)}`;
const getSessionInfoUrl = (protocol = "//", host = location.host) => `${protocol}${host}/node/sessioninfo`;

const getLoginUrl = (loginPath = "login.html", protocol = "//", host = location.host) =>
    `${protocol}${host}/${loginPath}`;

export const requestPsSessionCheck = (authCheckUrl: string, onSuccess?: () => void, onFail?: () => void) => {
    fetch(authCheckUrl, { credentials: "include" })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            return response;
        })
        .then((authenticated) => {
            if (navigator.cookieEnabled && authenticated === true) {
                onSuccess();
            } else {
                onFail();
            }
        })
        .catch((err) => {
            console.error("Session check error", err);
            onFail();
        });
};

export const usePsSessionCheck = (authApp: string, pathOnFail = "login.html", interval = 10000) => {
    let intervalId: number;

    const sessionCheckHook = createEventHook<boolean>();
    const start = () => {
        if (intervalId) {
            window.clearInterval(intervalId);
        }
        intervalId = window.setInterval(() => {
            requestPsSessionCheck(
                getAuthCheckUrl(authApp),
                () => {
                    sessionCheckHook.trigger(true);
                },
                () => {
                    stop();
                    sessionCheckHook.trigger(false);
                    window.location.href = getLoginUrl(pathOnFail);
                }
            );
        }, interval);
    };

    const stop = () => {
        window.clearInterval(intervalId);
    };

    return {
        start,
        stop,
        onSessionCheck: sessionCheckHook.on
    };
};

export interface ISessionInfo {
    userNames: {
        [authApp: string]: string;
    };
}
export const requestPsUserName = (authApp: string) =>
    new Promise<string>((resolve, reject) => {
        fetch(getSessionInfoUrl(), { credentials: "include" })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return response;
            })
            .then((response: ISessionInfo) => {
                const userName = response?.userNames?.[authApp];
                if (userName) {
                    resolve(userName);
                } else {
                    console.warn(`No user-name found for auth-app: ${authApp}`);
                    resolve(undefined);
                }
            })
            .catch((err) => {
                console.error("Request username error", err);
                reject();
            });
    });

export const doPsLogout = (authApp: string) => {
    location.href = getAuthLogoutUrl(authApp);
};

export const checkAvailablePsFeature = (authApp: string, featureKey: string) => {
    const featureUrl = getAuthFeatureCheckPath(authApp, featureKey);

    return new Promise<boolean>((resolve) => {
        fetch(featureUrl, { credentials: "include" })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return response;
            })
            .then((enabled: boolean) => {
                resolve(enabled === true);
            })
            .catch((err) => {
                console.error(`Failed getting feature info for: ${featureUrl}.`, err);
            });
    });
};
