import { isFunction } from "lodash-es";

export const isEmbedded = () => window.self !== window.top;

export const hasDocumentReferrer = () => document.referrer && document.referrer?.length > 0;

export const includesExpectedDocumentReferrer = (expectedReferrer: string[]) =>
    expectedReferrer.find((ref) => document.referrer?.includes(ref));

export const checkForAuthorizedIntegration = (expectedReferrer: string[]): boolean => {
    // check if embedded as iframe
    if (!isEmbedded()) {
        console.warn("Not embedded as iframe.");
        return false;
    }

    // check if non-empty 'document.referrer' is present
    if (!hasDocumentReferrer()) {
        console.warn("Expecting a non-empty 'document.referrer' to allow embedding of content.");
        return false;
    }

    // check if the embedding domain is whitelisted
    if (!includesExpectedDocumentReferrer(expectedReferrer)) {
        console.warn("'document.referrer' does not provide whitelisted domain.");
        return false;
    }

    return true;
};

// keys need to correspond to the keys used in '../scripts/iframe_listener.js'
enum PostMessagKeyTypes {
    HOST = "host",
    HEIGHT = "height"
}

interface IMessageEventData {
    key: PostMessagKeyTypes;
    value: string;
}

interface IMessageEvent {
    data: IMessageEventData;
    origin: string;
    lastEventId: string;
    source: object;
}

// setups cross-domain communication with domain which embedded the '../scripts/iframe_listener.js'-script
export const setupIframeCrossdomainCommunication = () => {
    let crossDomain: string;
    let resizeObserver: ResizeObserver;

    const sendHeightMessage = () => {
        if (window.parent.postMessage && crossDomain) {
            const bodyHeight = Math.max(document.body.clientHeight, document.body.offsetHeight);

            // defined object as payload send to the embedding domain
            const data = {
                key: PostMessagKeyTypes.HEIGHT,
                value: bodyHeight
            };
            // sends defined payload as message to embedding domain
            window.parent.postMessage(data, crossDomain);
        }
    };

    window.addEventListener(
        "message",
        (event: IMessageEvent) => {
            if (typeof event.data === "object" && event.data.key) {
                switch (event.data.key) {
                    case PostMessagKeyTypes.HOST:
                        {
                            crossDomain = event.data.value;
                            if (!resizeObserver) {
                                resizeObserver = new ResizeObserver(sendHeightMessage);
                                resizeObserver.observe(document.body);
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        },
        false
    );
};

export interface IframeIntegrationOptions {
    required: boolean;
    domains: string[];
}

export interface IframeIntegrationState extends IframeIntegrationOptions {
    authorized: boolean;
}

/**
 * Helper for checking and setup iframe-integration.
 * @param options - options to validate iframe-integration.
 * @param requiredCondition - callback-function to enable or disable that the iframe-integration is required.
 * @returns iframe integration information.
 */
export const checkAndSetupIframeIntegration = (
    options: IframeIntegrationOptions,
    requiredCondition?: (options: IframeIntegrationOptions) => boolean
) => {
    const iframeConfig: IframeIntegrationState = {
        required: isFunction(requiredCondition) ? requiredCondition(options) : options.required,
        domains: options.domains ?? [],
        authorized: false
    };

    if (document.referrer) {
        setupIframeCrossdomainCommunication();
    }

    if (iframeConfig?.required) {
        // if the app is embedded via an iframe then setup cross-domain-communication for dynamic height-adjustments
        iframeConfig.authorized = checkForAuthorizedIntegration(options?.domains);
    }

    return iframeConfig;
};
