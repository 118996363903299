<template>
    <component :is="tag">
        <slot :targetUrl="targetUrl">
            <WtcImage v-if="targetUrl" :class="pt.flag" :url="targetUrl"></WtcImage>
            <span v-if="natCode" class="nat-code" :class="pt.code">{{ natCode }}</span>
        </slot>
    </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

import WtcImage from "./WtcImage.vue";

interface IWtcFlagPassThrough {
    flag?: string;
    code?: string;
}

const props = withDefaults(
    defineProps<{
        flagCode?: string;
        flagPublicPath?: string;
        natCode?: string;
        sourceUrl?: string;
        fileExtension?: string;
        tag?: string;
        pt?: IWtcFlagPassThrough;
    }>(),
    {
        flagPublicPath: "flags/",
        fileExtension: "png",
        tag: "div",
        pt: (): IWtcFlagPassThrough => {
            return { flag: undefined, code: undefined };
        }
    }
);

const natUrl = computed(() => {
    const { flagCode, natCode, fileExtension } = props;
    if (flagCode || natCode) {
        return `${props.flagPublicPath}${flagCode ?? natCode}.${fileExtension}`;
    }
    return "";
});

const targetUrl = computed(() => (props.sourceUrl ?? natUrl.value).toLowerCase());
</script>
