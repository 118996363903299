import { ICompetitionFullMeta, ICompetitorDetail, IOrderReference } from "@swisstiming/webtec-kit";
import { isNil, orderBy } from "lodash-es";
import { computed, inject, onUnmounted, Ref, ref, watch } from "vue";

import { ChannelStatus } from "../enums/channel.enums";
import { RecordAreaType } from "../interfaces/channel/commons";
import { CHANNEL_PROVIDE_SYMBOL } from "../keys/provide.keys";
import { useChannelStore } from "../manager/vue-channel.manager";
import {
    createNameSpaceChannel,
    getCompStrucChannel,
    getCurrentChannel,
    getEntriesChannel,
    getEventBinariesChannel,
    getLiveChannel,
    getMedallistsChannel,
    getMedalTableChannel,
    getRecordsChannel,
    getRecordSetClassesChannel,
    getScheduleChannel,
    getSeasonChannelName,
    getStandingDataChannel
} from "../utils/channel.name-utils";

export const useChannelPlain = <TChannel>(channelName: string): Ref<TChannel> => {
    const { unsubscribeChannel, subscribeChannelContent } = useChannelStore();
    onUnmounted(() => {
        unsubscribeChannel(channelName);
    });
    return subscribeChannelContent<TChannel>(channelName);
};

export const useChannel = <TChannel>(channelName: Ref<string>) => {
    const { substituteChannel, unsubscribeChannel, retrieveChannel } = useChannelStore();

    watch(channelName, substituteChannel, { immediate: true });
    onUnmounted(() => {
        unsubscribeChannel(channelName.value);
    });
    return retrieveChannel<TChannel>(channelName);
};

export const useWatchedChannel = <TChannel>(channelName: Ref<string>) => {
    const { substituteChannel, retrieveChannel } = useChannelStore();
    const unWatch = watch(channelName, substituteChannel, { immediate: true });
    const { content, status } = retrieveChannel<TChannel>(channelName);
    return { unWatch, content, status };
};

/**
 * One time subscription to a given channel. For purposes at which a datastream is not needed, but only the initial state.
 * @param channelName - The name of the channel.
 */
export const usePromisedChannel = <TChannel>(channelName: string) => {
    const { subscribeChannel, retrieveChannel, unsubscribeChannel } = useChannelStore();

    return new Promise<TChannel>((resolve, reject) => {
        const { status, content } = retrieveChannel<TChannel>(ref(channelName));

        if (status.value === ChannelStatus.ANSWERED) {
            resolve(content.value);
        } else {
            subscribeChannel(channelName);
            const unWatch = watch(status, (newStatus) => {
                if (newStatus === ChannelStatus.ANSWERED) {
                    unWatch();
                    resolve(content.value);
                    unsubscribeChannel(channelName);
                } else if (newStatus === ChannelStatus.NOT_INITIALIZED) {
                    reject(ChannelStatus.NOT_INITIALIZED);
                }
            });
        }
    });
};

export const useChannels = <TChannel>(channelNames: Ref<string[]>, partial = false) => {
    const { PS_DefaultNameSpace, Channels, subscribeChannel, unsubscribeChannel } = useChannelStore();

    const relevantChannelNames = computed(() => channelNames.value?.filter((name) => !isNil(name)));

    const metaChannels = computed(
        () =>
            relevantChannelNames.value?.map((name) => Channels[createNameSpaceChannel(PS_DefaultNameSpace, name)]) ?? []
    );

    const status = computed(() => {
        const statusList = metaChannels.value?.map(({ status }) => status);

        if (statusList.every((s) => s === ChannelStatus.ANSWERED)) {
            return ChannelStatus.ANSWERED;
        } else if (statusList.some((s) => s === ChannelStatus.ANSWERED)) {
            return ChannelStatus.PARTIAL;
        } else if (statusList.some((s) => s === ChannelStatus.PENDING)) {
            return ChannelStatus.PENDING;
        } else {
            return ChannelStatus.NOT_INITIALIZED;
        }
    });

    const channels = computed<TChannel[]>(() => {
        switch (status.value) {
            case ChannelStatus.ANSWERED:
                return metaChannels.value.map((channel) => channel?.content);
            case ChannelStatus.PARTIAL:
                if (partial) {
                    return metaChannels.value.map((channel) => channel?.content);
                } else {
                    return [];
                }
            default:
                return [];
        }
    });

    watch(
        relevantChannelNames,
        (currentNames, prevNames) => {
            const addedChannels = currentNames?.filter((name) => !prevNames?.includes(name)) ?? [];
            const removedChannels = prevNames?.filter((name) => !currentNames?.includes(name)) ?? [];

            addedChannels.forEach((name) => subscribeChannel(name));
            removedChannels.forEach((name) => unsubscribeChannel(name));
        },
        { immediate: true }
    );

    onUnmounted(() => {
        channelNames.value.forEach((name) => unsubscribeChannel(name));
    });

    return {
        status,
        channels
    };
};

export const useTournamentChannelNames = (tournamentId: Ref<string>, seasonId?: Ref<string>) => {
    const channelNames = computed(() => {
        const { value: tId } = tournamentId;
        if (tId) {
            return {
                eventBinaries: getEventBinariesChannel(tId),
                standings: getStandingDataChannel(tId),
                schedule: getScheduleChannel(tId),
                current: getCurrentChannel(tId),
                live: getLiveChannel(tId),
                entries: getEntriesChannel(tId),
                medalTable: getMedalTableChannel(tId),
                medallists: getMedallistsChannel(tId),
                compStructure: getCompStrucChannel(tId),
                records: (recordType: RecordAreaType) => getRecordsChannel(tId, recordType),
                brokenRecords: getRecordSetClassesChannel(tId),
                season: seasonId.value ? getSeasonChannelName(seasonId.value) : undefined
            };
        }
        return {};
    });

    return { channelNames };
};

export const useProvidedChannel = <T extends ICompetitionFullMeta>() => inject<Ref<T>>(CHANNEL_PROVIDE_SYMBOL);

export const useMapIdToCompetitor = (
    targetMapList: { [x: string]: ICompetitorDetail },
    sortedList: IOrderReference
): ICompetitorDetail[] => {
    if (targetMapList && sortedList) {
        return orderBy(sortedList, (x) => x.ListIndex)
            .filter(({ Id }) => targetMapList[Id] !== undefined)
            .map(({ Id }) => {
                const competitor = targetMapList[Id];
                const Children = competitor.Children?.map((child) => targetMapList[child.Id] ?? child);
                return { ...competitor, Children };
            });
    }
};
