<template>
    <WtcTransition mode="out-in" name="slide-up">
        <div
            v-if="currentCompetitorFalseStartStatus"
            :key="currentCompetitorFalseStartStatus"
            class="mr-2 rounded px-2 py-1 text-xs"
            :class="statusStyle"
            v-html="label"
        ></div>
    </WtcTransition>
</template>
<script setup lang="ts">
import { useSledLiveChannel } from "@swisstiming/falcon-core";
import { getStrByLng, WtcTransition } from "@swisstiming/webtec-kit";
import { computed } from "vue";

import { SledFalseStartStatus } from "../enums/sled.enums";

const { currentCompetitorFalseStartStatus, currentCompetitorPenaltyTime } = useSledLiveChannel();

const statusStyle = computed(() => {
    switch (currentCompetitorFalseStartStatus.value) {
        case SledFalseStartStatus.ACCEPTED:
            return "bg-surface-negative text-black";
        case SledFalseStartStatus.PENDING:
            return "bg-orange-400 text-white";
        case SledFalseStartStatus.DECLINED:
            return "bg-surface-positive text-black";
        default:
            return undefined;
    }
});

const label = computed(() => {
    switch (currentCompetitorFalseStartStatus.value) {
        case SledFalseStartStatus.ACCEPTED:
            if (currentCompetitorPenaltyTime.value) {
                return `${getStrByLng().MESSAGE_EARLY_START_PENALTY_APPLIED}: ${currentCompetitorPenaltyTime.value}`;
            } else {
                return getStrByLng().MESSAGE_EARLY_START_PENALTY_APPLIED;
            }
        case SledFalseStartStatus.PENDING:
            return getStrByLng().MESSAGE_EARLY_START_UNDER_REVIEW;
        case SledFalseStartStatus.DECLINED:
            return getStrByLng().MESSAGE_EARLY_START_CORRECT_START;
        default:
            return undefined;
    }
});
</script>

<style scoped lang="scss"></style>
