import { useDateFormat } from "@vueuse/core";
export type CommonTimeTypes =
    | "HH:mm" // 14:12
    | "HH:mm:ss" // 14:12
    | "DD.MM HH:mm" // 01.01 14:12
    | "DD.MM.YYYY" // 01.01.2024
    | "DD.MM.YYYY, HH:mm" // 01.01.2024 14:12
    | "DD/MM" // 01.01
    | "dddd, DD. MMMM"
    | "DD. MMM YYYY"
    | "DD. MMMM YYYY" // 01. MAR 2024
    | "dddd, DD. MMMM YYYY, HH:mm"; // Monday, 30. September 2024, 10:09
import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import { Ref } from "vue";
export type CommonLocalisationArgument = "de" | "en" | "fr" | "no" | "sv" | "it" | "nl" | "po";
export const TIME_ZONE_OFFSET_SYMBOL = Symbol("globalTimeZone");
export const LOCALISATION_SYMBOL = Symbol("localisation");

export const HOUR = 60 * 60 * 1000;
export const TEN_MINUTES = 60 * 10 * 1000;
export const MINUTE = 60 * 1000;

export const useDateTime = (
    date: Ref<string | number | Date>,
    format: Ref<CommonTimeTypes>,
    locales: Ref<CommonLocalisationArgument>
) => useDateFormat(date, format, { locales: locales?.value });

export const useDateTimeWithOffset = (
    date: Ref<string | number | Date>,
    format: Ref<CommonTimeTypes>,
    locales: Ref<CommonLocalisationArgument>,
    offset: Ref<number> = ref(0)
) => {
    const adjustedDate = computed(() => {
        const originalDate = new Date(date.value);
        const localOffset = -originalDate.getTimezoneOffset() / 60;
        const offsetToAdd = (offset.value - localOffset) * 60 * 60000;

        return new Date(originalDate.getTime() + offsetToAdd);
    });

    return useDateTime(adjustedDate, format, locales);
};

export const useDateTrio = () => {
    const today = new Date();
    const tomorrow = new Date().setDate(today.getDate() + 1);
    const yesterday = new Date().setDate(today.getDate() - 1);

    return { today, tomorrow, yesterday };
};

export const useCurrentDayTime = (format: Ref<CommonTimeTypes> = ref("HH:mm:ss")) => {
    const currentTime = ref(Date.now());
    const timeZoneInject: Ref<number> = inject(TIME_ZONE_OFFSET_SYMBOL, undefined);
    const localesInject: Ref<CommonLocalisationArgument> = inject(LOCALISATION_SYMBOL, undefined);
    let interval;
    onMounted(() => {
        interval = window.setInterval(() => {
            currentTime.value = Date.now();
        }, 1000);
    });

    const dateString = computed(() => {
        if (timeZoneInject.value !== undefined) {
            return useDateTimeWithOffset(currentTime, format, localesInject, timeZoneInject).value;
        } else {
            return useDateTime(currentTime, format, localesInject).value;
        }
    });

    onUnmounted(() => {
        clearInterval(interval);
    });

    return { currentTime, dateString };
};
