<template>
    <component :is="tag" class="rank">{{ irm ?? rank }}</component>
</template>
<script setup lang="ts">
import { useIrmName } from "@swisstiming/falcon-core";
import { computed, PropType } from "vue";

const props = defineProps({
    tag: {
        type: String,
        default: "td"
    },
    competitor: {
        type: Object as PropType<{ IRM?: string; Rank?: string; RankEqual?: boolean }>,
        default: (): { IRM?: string; Rank?: string } => {
            return {};
        },
        required: true
    }
});
const irmKey = computed(() => props.competitor?.IRM);
const irm = useIrmName(irmKey);
const rank = computed(() => (props.competitor.RankEqual ? `=${props.competitor?.Rank}` : props.competitor.Rank));
</script>
<style></style>
