export enum SledCompetitorStatus {
    AT_START = "AT_START",
    ON_TRACK = "ON_TRACK",
    FINISHED = "FINISHED"
}

export enum SledFalseStartStatus {
    PENDING = "PENDING",
    DECLINED = "DECLINED",
    ACCEPTED = "ACCEPTED"
}

export enum SledRecordIds {
    SR = "SR",
    TR = "TR"
}
