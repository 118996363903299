<template>
    <WtcTransition appear name="fade-only-in">
        <div class="animation-wrapper">
            <div
                class="theme--header-wrapper max-tablet:flex-col-reverse max-tablet:items-start bg-brand flex items-center justify-between"
            >
                <div class="theme--header flex flex-col justify-center p-2 text-lg text-white">
                    <div
                        v-if="currentEventData?.Header1"
                        class="theme--header-title theme--header-title-event text-2xl uppercase text-white"
                    >
                        {{ currentEventData?.Header1 }}
                    </div>
                    <div v-if="locationHeader" class="theme--header-title text-sm leading-none text-white/60">
                        {{ locationHeader }}
                    </div>
                    <div v-if="competitionHeader" class="theme--header-title font-normal_light mt-2">
                        {{ competitionHeader }}
                    </div>
                </div>
                <BsnNextUp></BsnNextUp>
            </div>

            <div
                v-if="currentChannel.StartRecord?.Time || currentChannel.TrackRecord?.Time"
                class="theme--content-wrapper max-laptop:px-3 mt-3 flex flex-row gap-3"
            >
                <WtcTransition appear :delay-ms="200" name="slide-down-only-in">
                    <BsnRecord
                        v-if="currentChannel.StartRecord?.Time"
                        :label="$getStrByLng().LABEL_START_RECORD"
                        :record="currentChannel.StartRecord"
                    />
                </WtcTransition>

                <WtcTransition appear :delay-ms="200" name="slide-down-only-in">
                    <BsnRecord
                        v-if="currentChannel.TrackRecord?.Time"
                        :label="$getStrByLng().LABEL_TRACK_RECORD"
                        :record="currentChannel.TrackRecord"
                    />
                </WtcTransition>
            </div>
            <div class="theme--content-wrapper max-laptop:px-3 w-full">
                <WtcTransition appear :delay-ms="400" name="slide-right-only-in">
                    <div class="animation-wrapper">
                        <!-- current -->
                        <div class="theme--component-wrapper bg-surface-secondary mt-3 rounded border border-black/10">
                            <div
                                class="theme--component-header bg-brand font-normal_light flex items-center rounded-t px-3 py-2 text-sm leading-none text-white"
                            >
                                {{ $getStrByLng().LABEL_CURRENT }}
                            </div>
                            <div
                                class="theme--competitor theme--current-competitor max-tablet:flex-col flex flex-row border-b border-black/10 p-3"
                            >
                                <BsnCompetitor class="mr-2" :competitor="currentChannel.Current" />
                                <div class="competitor-info max-tablet:mt-2 flex items-center">
                                    <WtcTransition mode="out-in" name="slide-up">
                                        <div
                                            v-if="mappedStatusLabel"
                                            :key="currentChannel.Stats?.Status"
                                            class="mr-3 rounded px-2 py-1 text-xs"
                                            :class="statusStyle"
                                        >
                                            {{ mappedStatusLabel }}
                                        </div>
                                    </WtcTransition>

                                    <SledFalseStart></SledFalseStart>

                                    <WtcTransition name="slide-left-only-in">
                                        <div class="flex">
                                            <div v-if="showAdvantage" class="text-sm">
                                                <span class="mr-1">{{ $getStrByLng().LABEL_ADVANTAGE }}:</span>
                                                <span class="text-content-positive font-normal_bold">{{
                                                    currentChannel.Stats.Advantage
                                                }}</span>
                                            </div>
                                            <div v-if="rankBeforeRun" class="ml-3 text-sm">
                                                <span class="mr-1">{{ $getStrByLng().MESSAGE_CURRENTLY }}:</span>
                                                <span class="font-normal_bold">{{ rankBeforeRun }}</span>
                                            </div>
                                        </div>
                                    </WtcTransition>
                                </div>
                            </div>
                            <div
                                class="theme--box-wrapper max-tablet:gap-2 flex flex-row flex-wrap items-start justify-between gap-1 p-3"
                            >
                                <div class="theme--box rounded border border-black/10">
                                    <div class="theme--box-header font-normal_bold p-2 text-sm">
                                        {{ $getStrByLng().LABEL_LIVE_TIMING }}
                                    </div>
                                    <div class="mb-2 border-b border-black/10 pb-2 text-sm last:mb-0 last:border-b-0">
                                        <BsnCurrentIntermediates
                                            v-for="competitor in currentCompetitors"
                                            :key="competitor.Id"
                                            :current-channel="currentChannel"
                                            :current-competitor="competitor"
                                            :measure-box-channel="measureBoxChannel"
                                        />
                                    </div>
                                    <div class="mb-2 border-b border-black/10 pb-2 text-sm last:mb-0 last:border-b-0">
                                        <BsnCurrentRuns :current-channel="currentChannel" />
                                    </div>
                                </div>
                                <div v-if="hasCompetitionSensorData" class="theme--box rounded border border-black/10">
                                    <BsnSensorDataInfo :current-channel="currentChannel" />
                                </div>
                            </div>
                        </div>
                        <div class="max-tablet:flex-col flex gap-2">
                            <!-- previous -->
                            <div
                                v-if="currentChannel.Previous?.AthleteId"
                                class="theme--component-wrapper bg-surface-secondary mr-0 mt-3 flex-1 rounded border border-black/10"
                            >
                                <div
                                    class="theme--component-header bg-brand font-normal_light flex items-center rounded-t px-3 py-2 text-sm leading-none text-white"
                                >
                                    {{ $getStrByLng().LABEL_PREVIOUS }}
                                </div>
                                <div
                                    class="theme--competitor max-tablet:flex-col max-tablet:items-start flex flex-row items-center"
                                >
                                    <BsnCompetitor class="mr-2 flex-1" :competitor="currentChannel.Previous" />
                                    <div
                                        v-if="currentChannel.Previous?.Rank || currentChannel.Previous?.Result"
                                        class="max-tablet:mt-3"
                                    >
                                        <div class="max-tablet:flex-wrap flex flex-row gap-3">
                                            <div
                                                v-if="currentChannel.Previous?.Rank"
                                                class="bg-surface flex h-6 flex-row items-center rounded-lg p-2 text-sm"
                                            >
                                                <div class="mr-2 text-xs leading-3">
                                                    {{ $getStrByLng().LABEL_TOTAL_RANK }}
                                                </div>
                                                <div class="font-normal_bold flex items-center text-sm leading-none">
                                                    {{ currentChannel.Previous?.Rank }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="currentChannel.Previous?.Result"
                                                class="bg-surface flex h-6 flex-row items-center rounded-lg p-2 text-sm"
                                            >
                                                <div class="mr-2 text-xs leading-3">
                                                    {{ $getStrByLng().LABEL_TOTAL_TIME }}
                                                </div>
                                                <div class="font-normal_bold flex items-center text-sm leading-none">
                                                    {{ currentChannel.Previous?.Result }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- next -->
                            <div
                                v-if="currentChannel.Next?.AthleteId"
                                class="theme--component-wrapper bg-surface-secondary ml-0 mt-3 flex-1 rounded border border-black/10"
                            >
                                <div
                                    class="theme--component-header bg-brand font-normal_light flex items-center rounded-t px-3 py-2 text-sm leading-none text-white"
                                >
                                    {{ $getStrByLng().LABEL_NEXT }}
                                </div>
                                <div class="theme--competitor max-tablet:flex-col flex-row">
                                    <BsnCompetitor :competitor="currentChannel.Next" />
                                </div>
                            </div>
                        </div>
                    </div>
                </WtcTransition>
                <div
                    class="theme--component-wrapper max-tablet:w-full bg-surface-secondary my-3 flex-1 rounded border border-black/10"
                >
                    <WtcTransition appear :delay-ms="600" name="slide-up-only-in"
                        ><div class="animation-wrapper">
                            <div class="theme--component-header font-normal_bold rounded-t px-3 py-2 text-sm">
                                {{ $getStrByLng().LABEL_RESULTS }}
                            </div>
                            <div class="theme--table-wrapper">
                                <div class="max-laptop:overflow-x-auto">
                                    <BsnRelayResultList v-if="isRelay" :current-channel="currentChannel" />
                                    <BsnNonRelayResultList v-else :current-channel="currentChannel" />
                                </div>
                            </div>
                        </div>
                    </WtcTransition>
                </div>
            </div>
        </div>
    </WtcTransition>
</template>

<script setup lang="ts">
import { ISledLiveChannel, useFalconStore, useSledMeasureBoxChannel } from "@swisstiming/falcon-core";
import { SledCompetitorStatus, SledFalseStart } from "@swisstiming/sled-core";
import { CommonCompTypes, getCombinedValue, getStrByLng, toOrdinal, WtcTransition } from "@swisstiming/webtec-kit";
import { isEmpty } from "lodash-es";
import { computed } from "vue";

import BsnCurrentIntermediates from "../components/BsnCurrentIntermediates.vue";
import BsnCurrentRuns from "../components/BsnCurrentRuns.vue";
import BsnNonRelayResultList from "../components/BsnNonRelayResultList.vue";
import BsnRelayResultList from "../components/BsnRelayResultList.vue";
import BsnCompetitor from "../components/elements/BsnCompetitor.vue";
import BsnNextUp from "../components/elements/BsnNextUp.vue";
import BsnRecord from "../components/elements/BsnRecord.vue";
import BsnSensorDataInfo from "../components/elements/BsnSensorDataInfo.vue";

const props = defineProps<{
    currentChannel?: ISledLiveChannel;
}>();

const falconStore = useFalconStore();

const currentEventData = computed(() => falconStore.data?.currentEventData);
const { measureBoxChannel } = useSledMeasureBoxChannel();

const isRelay = computed(() => props.currentChannel.Stats?.Type?.toLowerCase() === CommonCompTypes.RELAY.toLowerCase());

const locationHeader = computed(() => {
    const header2 = currentEventData.value?.Header2;
    const header3 = currentEventData.value?.Header3;

    return getCombinedValue(header2, header3, " / ");
});

const competitionHeader = computed(() => {
    const eventName = props.currentChannel?.EventName;
    const unitName = props.currentChannel?.UnitName;
    return getCombinedValue(eventName, unitName, " - ");
});

const mappedStatusLabel = computed(() => {
    switch (props.currentChannel?.Stats?.Status) {
        case SledCompetitorStatus.AT_START:
            return getStrByLng().LABEL_AT_START;
        case SledCompetitorStatus.ON_TRACK:
            return getStrByLng().LABEL_ON_TRACK;
        case SledCompetitorStatus.FINISHED:
            return getStrByLng().LABEL_FINISHED;
        default:
            return "";
    }
});

const statusStyle = computed(() => {
    switch (props.currentChannel?.Stats?.Status) {
        case SledCompetitorStatus.AT_START:
            return "bg-surface-comingup text-content-comingup";
        case SledCompetitorStatus.ON_TRACK:
            return "bg-surface-running text-content-running";
        case SledCompetitorStatus.FINISHED:
            return "bg-surface-finished text-content-finished";
        default:
            return "bg-black text-white";
    }
});

const showAdvantage = computed(() => {
    const advantage = parseFloat(props.currentChannel?.Stats?.Advantage);
    const status = props.currentChannel?.Stats?.Status;

    return !isNaN(advantage) && advantage < 0 && status !== SledCompetitorStatus.FINISHED;
});

const rankBeforeRun = computed(() => {
    const status = props.currentChannel?.Stats?.Status;
    const runs = props.currentChannel?.Current?.Runs ?? [];
    if (status === SledCompetitorStatus.AT_START && runs.length > 1) {
        const rank = runs.at(runs.length - 2)?.Rank; // rank of previous run
        if (rank) {
            return `${toOrdinal(rank)}`;
        }
    }
    return undefined;
});

const currentCompetitors = computed(() =>
    props.currentChannel?.Stats?.Type === CommonCompTypes.RELAY
        ? (props.currentChannel?.Current?.Children ?? [])
        : isEmpty(props.currentChannel?.Current)
          ? []
          : [props.currentChannel?.Current]
);

const hasCompetitionSensorData = computed(
    () => measureBoxChannel.value.CompetitionCode === props.currentChannel?.Rsc?.Value
);
</script>
