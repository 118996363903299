<template>
    <ul class="flex flex-wrap">
        <BsnHighlightedValue
            v-for="(int, index) in activeCompetitor?.Intermediate"
            :key="int.Number"
            :displayed-value="
                (activeTeamMember?.Stats?.FalseStartStatus === SledFalseStartStatus.PENDING ||
                    activeTeamMember?.Stats?.FalseStartStatus === SledFalseStartStatus.DECLINED) &&
                index === 0
                    ? ''
                    : int.Result
            "
            :label="int.Name"
            :text-negative="hasPenaltyTime"
        >
            <template #value-prefix>
                <SledRecordBadge
                    class="bg-surface-highlightLight text-content-highlight p-1 text-xs"
                    :record-string="getRecordString(index)"
                ></SledRecordBadge>
            </template>
        </BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="currentIntermediate?.Rank"
            :label="$getStrByLng().LABEL_RANK"
        ></BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="currentSpeedInformation"
            :label="$getStrByLng().LABEL_SPEED"
        ></BsnHighlightedValue>

        <BsnHighlightedValue
            v-if="activeCompetitor?.Stats?.PenaltyTime"
            :displayed-value="activeCompetitor?.Stats?.PenaltyTime"
            :label="$getStrByLng().LABEL_PENALTY"
            :text-negative="hasPenaltyTime"
        ></BsnHighlightedValue>
    </ul>
</template>

<script setup lang="ts">
import { ISledCurrentCompetitor, ISledLiveChannel, ISledMeasureBoxChannel } from "@swisstiming/falcon-core";
import { SledFalseStartStatus, SledRecordBadge, SledRecordIds } from "@swisstiming/sled-core";
import { getStrByLng } from "@swisstiming/webtec-kit";
import { isNil } from "lodash-es";
import { computed } from "vue";

import BsnHighlightedValue from "./elements/BsnHighlightedValue.vue";
const props = defineProps<{
    currentCompetitor: ISledCurrentCompetitor;
    measureBoxChannel: ISledMeasureBoxChannel;
    currentChannel?: ISledLiveChannel;
}>();

const activeCompetitor = computed(() => {
    const childId = props.currentCompetitor.CurrentChildId;
    return childId ? props.currentCompetitor.Children[childId] : props.currentCompetitor;
});

const activeTeamMember = computed(() => {
    const childId = props.currentCompetitor.CurrentChildId;
    return childId !== undefined ? props.currentCompetitor.Children[childId] : undefined;
});

const currentIntermediate = computed(() => {
    const currentIntIndex = activeCompetitor.value?.CurrentIntermediate;
    return activeCompetitor.value?.Intermediate?.at(currentIntIndex);
});

const currentSpeedInformation = computed(() => {
    const currentCompetitorId = activeCompetitor.value?.Id;
    const measureBoxCompetitorId = props.measureBoxChannel?.CompetitorCode;
    const currentSpeedIndex = activeCompetitor.value?.CurrentSpeed;

    const speed =
        currentCompetitorId === measureBoxCompetitorId
            ? props.measureBoxChannel?.Data?.SpeedKmh
            : activeCompetitor.value?.Speeds.at(currentSpeedIndex)?.Kmh;

    return speed !== "" && !isNil(speed) ? `${speed} ${getStrByLng().LABEL_SPEED_UNIT}` : undefined;
});

const getRecordString = (intIndex: number) => {
    const recordType = props.currentChannel?.Current?.Record;
    const splitCount = props.currentChannel?.SplitCount;

    if (recordType) {
        if (intIndex === 0 && recordType && recordType === SledRecordIds.SR) {
            return SledRecordIds.SR;
        } else if (intIndex === splitCount - 1 && recordType && recordType === SledRecordIds.TR) {
            return SledRecordIds.TR;
        } else {
            return undefined;
        }
    }

    return undefined;
};

const hasPenaltyTime = computed(
    () => activeCompetitor.value?.Stats?.PenaltyTime !== undefined && activeCompetitor.value?.Stats?.PenaltyTime !== ""
);
</script>
