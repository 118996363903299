export enum BsnCompetitionTypes {
    SKELETON = "Single",
    MONO = "Mono",
    TWO = "Team2",
    FOUR = "Team4",
    RELAY = "Relay"
}

export enum BsnDisciplineTypes {
    SKELETON = "SKN"
}

export enum BsnProfiles {
    OMEGA = "omega"
}
