import { defineStore } from "pinia";
import { computed, ComputedRef, reactive, Ref, ref, toRef, watch } from "vue";

import { ChannelStatus } from "../enums/channel.enums";
import { IChannelMeta } from "../interfaces/channel";
import { handleWarn } from "../stores/logging.store";
import { createNameSpaceChannel } from "../utils/channel.name-utils";
import { IPushClientOptions, PSClientManager } from "./pushserver-client.manager";

export enum CONNECTION_STATUS {
    CONNECTED = "connected",
    DISCONNECTED = "disconnected",
    FAILED = "failed"
}

export enum AUTHENTICATION_STATES {
    PENDING = "pending",
    AUTHORIZED = "authorized",
    FAILED = "failed"
}

const JSON_DIFF_TYPES = {
    DELETE: "_jsondiff_del",
    DIFF_TYPE: "__jsondiff_t",
    DIFF_TYPE_ARRAY: "a",
    DIFF_TYPE_ARRAY_INSERT: "i",
    DIFF_TYPE_ARRAY_DELETE: "d"
};

export const VuePushClientDiffParser = {
    applyPatch(channel: object, diff): void {
        this.patchObject(channel, diff);
    },

    patchObject(obj, diff): void {
        for (const key in diff) {
            const newValue = diff[key];

            if (newValue === JSON_DIFF_TYPES.DELETE) {
                delete obj[key];
                // obj[key] = undefined;
            } else if (typeof newValue === "object") {
                if (!Array.isArray(newValue)) {
                    if (newValue === null) {
                        obj[key] = newValue;
                    } else if (newValue[JSON_DIFF_TYPES.DIFF_TYPE] === JSON_DIFF_TYPES.DIFF_TYPE_ARRAY) {
                        this.patchArray(obj[key], newValue);
                    } else {
                        const oldValue = obj[key];

                        if (typeof oldValue === "object" && oldValue !== null) {
                            this.patchObject(oldValue, newValue);
                        } else {
                            obj[key] = newValue;
                        }
                    }
                } else {
                    obj[key] = newValue;
                }
            } else {
                obj[key] = newValue;
            }
        }
    },

    patchArray(node, diff): void {
        const updates = diff.u;
        if (updates) {
            for (const key in updates) {
                const newValue = updates[key];

                if (typeof newValue === "object") {
                    if (newValue[JSON_DIFF_TYPES.DIFF_TYPE] === JSON_DIFF_TYPES.DIFF_TYPE_ARRAY) {
                        this.patchArray(node[key], newValue);
                    } else {
                        this.patchObject(node[key], newValue);
                    }
                } else {
                    node[key] = newValue;
                }
            }
        }

        const inserts = diff[JSON_DIFF_TYPES.DIFF_TYPE_ARRAY_INSERT];
        if (inserts) {
            node.splice(node.length, 0, ...inserts);
        } else {
            const deletes = diff[JSON_DIFF_TYPES.DIFF_TYPE_ARRAY_DELETE];
            if (deletes !== undefined) {
                node.splice(node.length - deletes, deletes);
            }
        }
    }
};

// currently used for RCL and NOT in LR, which means both implementation of 'vue-channel.manager' are currently in use
// refactor LR and remove "old" 'vue-channel.manager'

export const subscribeChannel = (channelName: string) => useChannelStore().subscribeChannel(channelName);
export const unsubscribeChannel = (channelName: string) => useChannelStore().unsubscribeChannel(channelName);
export const substituteChannel = (newChannel: string, oldChannel?: string) =>
    useChannelStore().substituteChannel(newChannel, oldChannel);
export const subscribeChannelContent = <TChannel>(channelName: string): Ref<TChannel> =>
    useChannelStore().subscribeChannelContent<TChannel>(channelName);

export const retrieveChannelContent = <TChannel>(channelName: Ref<string | undefined>): ComputedRef<TChannel> =>
    useChannelStore().retrieveChannelContent<TChannel>(channelName);

export const useChannelStore = defineStore("CHANNEL_STORE", () => {
    let PS_Instance;
    const PS_AuthorizedNamespaces: Map<string, { status: AUTHENTICATION_STATES; queue: (() => void)[] }> = new Map();

    const PS_DefaultNameSpace = ref<string>();
    const PS_ConnectionStatus = ref<CONNECTION_STATUS>(CONNECTION_STATUS.DISCONNECTED);
    const Channels = reactive<{ [x: string]: IChannelMeta }>({});
    const isConnected = computed(() => PS_ConnectionStatus.value === CONNECTION_STATUS.CONNECTED);

    const connect = (pushclientLib, clientOptions: IPushClientOptions) => {
        PS_Instance = PSClientManager.connect(pushclientLib, clientOptions);

        PS_DefaultNameSpace.value = clientOptions.defaultNamespace;

        // Register events
        PSClientManager.registerEvent("connected", () => {
            PS_ConnectionStatus.value = CONNECTION_STATUS.CONNECTED;
        });

        PSClientManager.registerEvent("disconnected", () => {
            PS_ConnectionStatus.value = CONNECTION_STATUS.DISCONNECTED;
        });

        PSClientManager.registerEvent("connectFailed", (err) => {
            PS_ConnectionStatus.value = CONNECTION_STATUS.FAILED;
            console.error(err);
        });

        PSClientManager.registerEvent("channelInit", (channelName, data, version) => {
            setChannelInit(channelName, data, version);
        });

        PSClientManager.registerEvent("channelUpdate", (channelName, patch, sync) => {
            const channel = Channels[channelName];
            VuePushClientDiffParser.applyPatch(channel.content, patch);
            channel.version = sync;
        });

        PSClientManager.registerEvent("channelNotInitialized", (channelName) => {
            Channels[channelName].status = ChannelStatus.NOT_INITIALIZED;
        });

        PSClientManager.registerEvent("uploadAuthorization", (success: boolean, namespace: string) => {
            const authentication = PS_AuthorizedNamespaces.get(namespace);
            if (!authentication) {
                PS_AuthorizedNamespaces.set(namespace, { status: AUTHENTICATION_STATES.PENDING, queue: [] });
            }

            PS_AuthorizedNamespaces.get(namespace).status = success
                ? AUTHENTICATION_STATES.AUTHORIZED
                : AUTHENTICATION_STATES.FAILED;
        });

        return PS_Instance;
    };

    const setChannelInit = (channelName: string, data: any, version: number) => {
        const channel = Channels[channelName];
        channel.content = data;
        channel.status = ChannelStatus.ANSWERED;
        channel.version = version;
    };
    const createChannel = (channelName: string) => {
        Channels[channelName] = {
            version: -Infinity,
            status: ChannelStatus.PENDING,
            content: {},
            subscriber: 1 // add the subscriber, who was joining the channel
        };
        return Channels[channelName];
    };
    const removeSubscriber = (channelName: string) => {
        const channel = Channels[channelName];
        channel.subscriber--;
        if (channel.subscriber === 0) {
            Channels[channelName] = {
                version: -Infinity,
                status: ChannelStatus.REMOVED,
                content: {},
                subscriber: 0 // add the subscriber, who was joining the channel
            };
            PS_Instance.unsubscribe(channelName).catch(handleWarn);
        }
    };

    const subscribeChannel = (channelName: string) => {
        if (channelName) {
            const cName = createNameSpaceChannel(PS_DefaultNameSpace.value, channelName?.toUpperCase());
            const channel = Channels[cName];
            if (!channel || channel.status === ChannelStatus.REMOVED) {
                PS_Instance.subscribe(cName);
                return createChannel(cName);
            } else {
                channel.subscriber++;
                return channel;
            }
        }
    };

    const unsubscribeChannel = (channelName: string | undefined) => {
        if (channelName) {
            const cName = createNameSpaceChannel(PS_DefaultNameSpace.value, channelName?.toUpperCase());
            const channel = Channels[cName];
            if (channel) {
                removeSubscriber(cName);
            } else {
                console.warn(`Subscription removed for channel ${cName}, but there aren't any subscriber left.`);
            }
        }
    };

    const subscribeChannelContent = <TChannel>(channelName: string): Ref<TChannel> =>
        toRef(subscribeChannel(channelName), "content");

    const retrieveChannelContent = <TChannel>(channelName: Ref<string | undefined>): ComputedRef<TChannel> =>
        computed(() => {
            const cName = createNameSpaceChannel(PS_DefaultNameSpace.value, channelName.value?.toUpperCase());
            const channel = Channels[cName];
            return channel?.content ?? {};
        });

    const retrieveChannel = <TChannel>(channelName: Ref<string | undefined>) => {
        const cName = computed(() =>
            createNameSpaceChannel(PS_DefaultNameSpace.value, channelName.value?.toUpperCase())
        );
        const status = computed(() => Channels[cName.value]?.status);
        const isAnswered = computed(() => status.value === ChannelStatus.ANSWERED);
        return { content: retrieveChannelContent<TChannel>(channelName), status, isAnswered };
    };

    const substituteChannel = (newChannel?: string, oldChannel?: string) => {
        if (oldChannel) {
            unsubscribeChannel(oldChannel);
        }
        if (newChannel) {
            return subscribeChannel(newChannel);
        }
    };

    // TODO: Might be the equivalent to 'substituteChannel' > check out and potentially remove. It more looks like the "useChannel" composable.
    // However the useChannel composable expects to be called within a component livecycle. This one could become the one to substitute, when outside livecycle. But its not returning the actual channel data.
    const switchChannelSubscription = (channelName: Ref<string | undefined>) => {
        let currentChannelName: string;
        const unwatch = watch(channelName, (channelName, oldChannelName) => {
            if (oldChannelName) {
                unsubscribeChannel(oldChannelName);
            }
            if (channelName) {
                currentChannelName = channelName;
                subscribeChannel(channelName);
            }
        });

        // return unsubscribe handler
        return () => {
            unwatch();
            if (currentChannelName) {
                unsubscribeChannel(currentChannelName);
            }
        };
    };

    //#region authorization and upload
    const authorizeForUpload = (namespace: string, user = "user1", password = "pw1") => {
        PS_AuthorizedNamespaces.set(namespace, { status: AUTHENTICATION_STATES.PENDING, queue: [] });
        PS_Instance.authorizeUpload(namespace, user, password)
            .then((response) => {
                // promise is resolved, if the server was responding either by a successful authorisation or a failed attempt (true/false)
                if (response) {
                    console.log(`Successfully authorized for ${namespace}`);
                    const namespaceAuth = PS_AuthorizedNamespaces.get(namespace);
                    namespaceAuth.status = AUTHENTICATION_STATES.AUTHORIZED;
                    namespaceAuth.queue.forEach((uploadRequest) => {
                        uploadRequest();
                    });
                } else {
                    console.log(`Authorization failed for ${namespace}`);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const isNamespaceAuthenticatedForUpload = (namespace: string) => {
        const authentication = PS_AuthorizedNamespaces.get(namespace);
        return authentication && authentication.status === AUTHENTICATION_STATES.AUTHORIZED;
    };

    const isNamespaceAuthorizationPending = (namespace: string) => {
        const authentication = PS_AuthorizedNamespaces.get(namespace);
        return authentication && authentication.status === AUTHENTICATION_STATES.PENDING;
    };

    const requestChannelUpload = (channelName: string, data: any, namespace: string) =>
        PS_Instance.upload(channelName, data, namespace)
            .then()
            .catch((err) => {
                console.log(err);
            });

    const requestBinaryUpload = (fileObj: any, namespace: string, binaryChannelKey?: string) =>
        PS_Instance.uploadBinary(fileObj, namespace, binaryChannelKey)
            .then()
            .catch((err) => {
                console.log(err);
            });

    const requestBinaryDeletion = (name: string, namespace: string, binaryChannelKey?: string) =>
        PS_Instance.deleteBinary(name, namespace, binaryChannelKey).catch((err) => {
            console.log(err);
        });

    const uploadChannel = (channelName: string, data: any, namespace: string) => {
        if (isNamespaceAuthenticatedForUpload(namespace)) {
            requestChannelUpload(channelName, data, namespace);
        } else if (isNamespaceAuthorizationPending(namespace)) {
            PS_AuthorizedNamespaces.get(namespace).queue.push(() => {
                requestChannelUpload(channelName, data, namespace);
            });
        } else {
            console.warn(
                "Attempted to upload a channel for a not authorized namespace. Please use 'authorizeForUpload' method to authenticate first"
            );
        }
    };

    const uploadBinary = (fileObj: any, namespace: string, binaryChannelKey: string) => {
        if (isNamespaceAuthenticatedForUpload(namespace)) {
            requestBinaryUpload(fileObj, namespace, binaryChannelKey);
        } else if (isNamespaceAuthorizationPending(namespace)) {
            PS_AuthorizedNamespaces.get(namespace).queue.push(() => {
                requestBinaryUpload(fileObj, namespace, binaryChannelKey);
            });
        } else {
            console.warn(
                "Attempted to upload a binary for a not authorized namespace. Please use 'authorizeForUpload' method to authenticate first"
            );
        }
    };

    const deleteBinary = (name: string, namespace: string, binaryChannelKey: string) => {
        if (isNamespaceAuthenticatedForUpload(namespace)) {
            requestBinaryDeletion(name, namespace, binaryChannelKey);
        } else if (isNamespaceAuthorizationPending(namespace)) {
            PS_AuthorizedNamespaces.get(namespace).queue.push(() => {
                requestBinaryDeletion(name, namespace, binaryChannelKey);
            });
        } else {
            console.warn(
                "Attempted to delete a binary for a not authorized namespace. Please use 'authorizeForUpload' method to authenticate first"
            );
        }
    };

    return {
        PS_Instance,
        PS_DefaultNameSpace,
        PS_ConnectionStatus,
        isConnected,

        Channels,

        connect,

        subscribeChannel,
        unsubscribeChannel,
        substituteChannel,
        switchChannelSubscription,
        subscribeChannelContent,
        retrieveChannelContent,
        retrieveChannel,

        authorizeForUpload,
        uploadChannel,
        uploadBinary,
        deleteBinary
    };
});
