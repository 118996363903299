<template>
    <table class="w-full">
        <thead class="uppercase">
            <tr>
                <!-- rank -->
                <th class="!sticky left-0 w-12 min-w-12">{{ $getStrByLng().LABEL_RANK }}</th>
                <!-- bib -->
                <th class="w-12 min-w-12">{{ $getStrByLng().LABEL_BIB }}</th>
                <!-- noc -->
                <th class="w-10 min-w-10"></th>
                <!-- name -->
                <th class="text-left" colspan="3">
                    {{ $getStrByLng().LABEL_NAME }}
                </th>
                <!-- reaction -->
                <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_REACTION }}</th>
                <!-- run -->
                <th class="w-14 min-w-14">{{ timingChannel.NameShort }}</th>
                <!-- penalty -->
                <th class="w-14 min-w-14">{{ $getStrByLng().HEADER_PENALTY }}</th>
                <!-- total -->
                <th class="!sticky right-0 w-24 min-w-24 text-right">{{ $getStrByLng().LABEL_TOTAL_TIME }}</th>
            </tr>
        </thead>
        <WtcTransitionGroup
            v-if="sortedResultList?.length > 0"
            class="bsn-relay-body"
            :class="teamMemberPerTeamClass"
            name="list-sort"
            tag="tbody"
        >
            <template v-for="team in sortedResultListByPenalty" :key="team.Id">
                <template v-for="(teamMember, index) in team.Children" :key="teamMember.Id">
                    <tr :class="[getTWHighlightCompetitorClass(team, currentChannel.Current.Id)]">
                        <template v-if="index === 0">
                            <FalconRankIrm class="!sticky left-0 py-1" :competitor="team" />
                            <td class="py-1">{{ team.Bib }}</td>
                            <td class="py-1">
                                <FalconFlag :nat-code="team.Nationality"></FalconFlag>
                            </td>
                            <td class="min-w-28 text-wrap py-1 text-left leading-none">
                                <WtcName :competitor-name="team"></WtcName>
                            </td>
                        </template>
                        <template v-else>
                            <td class="!sticky left-0 py-1"></td>
                            <td class="py-1" colspan="3"></td>
                        </template>

                        <td
                            class="w-12 min-w-12 py-1"
                            :class="[getTWHighlightTeamMemberClass(teamMember, activeTeamMemberCompetitorId)]"
                        >
                            {{ teamMember.Bib }}
                        </td>
                        <td
                            class="min-w-40 text-wrap py-1 text-left leading-none"
                            :class="[getTWHighlightTeamMemberClass(teamMember, activeTeamMemberCompetitorId)]"
                        >
                            <WtcName :competitor-name="teamMember"></WtcName>
                        </td>
                        <td class="min-w-32 py-1" :class="{ '!text-content-negative': teamMember.HasPenaltyTime }">
                            <span
                                v-if="teamMember?.Stats?.FalseStartStatus === SledFalseStartStatus.PENDING"
                                class="bg-amber-400 px-1 pb-0.5 text-xs text-white"
                            >
                                CHECKING
                            </span>
                            <template v-else-if="teamMember?.Stats?.FalseStartStatus === SledFalseStartStatus.DECLINED">
                                <!-- Do not display the reaction time, if a declined penalty reaction start is present -->
                            </template>
                            <template v-else>
                                <span class="mr-1">{{ teamMember.Stats?.ReactionResult }}</span>
                                <span>{{ addBrackets(teamMember.Stats?.ReactionRank, "[") }}</span>
                            </template>
                        </td>
                        <td class="py-1" :class="{ '!text-content-negative': teamMember.HasPenaltyTime }">
                            {{ teamMember.Result }}
                        </td>
                        <td class="py-1" :class="{ '!text-content-negative': teamMember.HasPenaltyTime }">
                            {{ teamMember.Stats?.PenaltyTime }}
                        </td>

                        <td class="!sticky right-0 py-1 text-right">
                            <template v-if="index === 0">{{ team.Behind || team.Result }}</template>
                        </td>
                    </tr>
                </template>
            </template>
        </WtcTransitionGroup>
        <tbody v-else>
            <tr>
                <WtcPlaceholder
                    class="text-brand font-normal_bold uppercase"
                    colspan="100"
                    tag="td"
                    :text="$getStrByLng().MESSAGE_NO_DATA_AVAILABLE"
                />
            </tr>
        </tbody>
    </table>
</template>

<!-- Only the relay competition type is different, as here the athletes are having their runs after each other and not together. -->

<script setup lang="ts">
import {
    FalconFlag,
    FalconRankIrm,
    ISledLiveChannel,
    useSledLiveChannel,
    useSledTimingChannel
} from "@swisstiming/falcon-core";
import { SledFalseStartStatus } from "@swisstiming/sled-core";
import {
    addBrackets,
    getTWHighlightCompetitorClass,
    getTWHighlightTeamMemberClass,
    WtcName,
    WtcPlaceholder,
    WtcTransitionGroup
} from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = defineProps<{
    currentChannel: ISledLiveChannel;
}>();

const { sortedResultList, timingChannel } = useSledTimingChannel(computed(() => props.currentChannel?.Rsc?.Value));

const activeTeamMemberCompetitorId = computed(() => {
    const idFromLiveChannel = props.currentChannel.Current.Children[props.currentChannel.Current.CurrentChildId].Id;
    return timingChannel.value.CompetitorDetails[idFromLiveChannel].Id;
});

const sortedResultListByPenalty = computed(() =>
    sortedResultList.value?.map((competitor) => {
        return {
            ...competitor,
            Children: competitor.Children?.map((teamMember) => {
                return {
                    ...teamMember,
                    HasPenaltyTime:
                        teamMember.Stats?.PenaltyTime !== undefined ||
                        teamMember.Stats?.FalseStartStatus === SledFalseStartStatus.ACCEPTED
                };
            })
        };
    })
);

const teamMemberPerTeamClass = computed(() => `bsn-team-member-${sortedResultList.value?.at(0)?.Children?.length}`);
</script>
<style lang="scss">
.bsn-relay-body {
    tr:nth-child(odd) {
        td {
            @apply bg-surface-secondary;
        }
    }
    &.bsn-team-member-2 {
        tr:nth-child(4n + 1) {
            td {
                @apply bg-surface-alternate;
            }
        }
        tr:nth-child(4n + 2) {
            td {
                @apply bg-surface-alternate;
            }
        }
    }

    &.bsn-team-member-3 {
        tr:nth-child(6n + 1),
        tr:nth-child(6n + 2),
        tr:nth-child(6n + 3) {
            td {
                @apply bg-surface-alternate;
            }
        }
    }

    &.bsn-team-member-4 {
        tr:nth-child(8n + 1),
        tr:nth-child(8n + 2),
        tr:nth-child(8n + 3),
        tr:nth-child(8n + 4) {
            td {
                @apply bg-surface-alternate;
            }
        }
    }

    &.bsn-team-member-2,
    &.bsn-team-member-3,
    &.bsn-team-member-4 {
        tr.current-competitor {
            > td {
                @apply bg-surface-highlightLight !important;

                &:first-child {
                    @apply before:h-full before:border-l-4 before:border-surface-highlight before:content-['*'] before:absolute before:left-0 before:top-0 before:text-transparent relative;
                }
            }
        }
    }

    td.current-team-member {
        @apply text-content-highlight;

        .wtc-name {
            @apply text-content-highlight;
        }
    }
}
</style>
