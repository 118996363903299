import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getStrings, wtcHighlightDirective, wtcUIPlugin } from "@swisstiming/webtec-kit";
import { createPinia } from "pinia";
import { Plugin } from "vue";
import { Router } from "vue-router";

import { IFalconStoreOptions, useFalconStore } from "../stores/falcon.store";

interface IFalconPluginOptions {
    title: string;
    router: Router;
    storeOptions: IFalconStoreOptions;
}

export const falconPlugin: Plugin<IFalconPluginOptions> = {
    install(app, options) {
        // App defaults
        console.info(`Application ${__APP_NAME__} version ${__APP_VERSION__}`);
        document.title = options.title;

        app.use(createPinia());

        // use provided router - this is mandatory even if there is only one route
        app.use(options.router);

        // Additional Plugins and directives ready for every federation result page
        app.use(wtcUIPlugin);

        // Directives
        app.directive("highlight", wtcHighlightDirective);

        // Global Components
        // TODO: why two times with different name?
        app.component("font-awesome-icon", FontAwesomeIcon);
        app.component("FontAwesomeIcon", FontAwesomeIcon);

        // Init Store and connect to Pushserver, but do not subscribe any channel, as these might all be different.
        const { initFalconStore } = useFalconStore();
        app.config.globalProperties.$getStrByLng = () => getStrings();
        initFalconStore(options.storeOptions);

        return app;
    }
};
