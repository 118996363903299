<template>
    <ul class="flex flex-wrap">
        <template v-if="!isRelay">
            <!-- dont show if relay competition as its the same as the 'Current.Result' -->
            <BsnHighlightedValue
                v-for="run in currentChannel?.Current?.Runs"
                :key="run.Id"
                :displayed-value="run.Result"
                :label="run.Name"
            ></BsnHighlightedValue>
        </template>
        <BsnHighlightedValue
            :displayed-value="currentChannel?.Current?.Result"
            :label="$getStrByLng().LABEL_TOTAL"
        ></BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="relevantTotalGap"
            :label="$getStrByLng().LABEL_TOTAL_GAP"
        ></BsnHighlightedValue>
        <BsnHighlightedValue
            :displayed-value="currentChannel?.Current?.Rank"
            :label="$getStrByLng().LABEL_RANK"
        ></BsnHighlightedValue>
    </ul>
</template>

<script setup lang="ts">
import { ISledLiveChannel } from "@swisstiming/falcon-core";
import { computed } from "vue";

import { BsnCompetitionTypes } from "../enums/bsn.enums";
import BsnHighlightedValue from "./elements/BsnHighlightedValue.vue";

const props = defineProps<{
    currentChannel?: ISledLiveChannel;
}>();

const currentIntermediate = computed(() => {
    const currentIntIndex = props.currentChannel?.Stats?.CurSplit;
    return props.currentChannel?.Current?.Intermediate?.at(currentIntIndex);
});

// relay specific adjustments
const isRelay = computed(
    () => (props.currentChannel.Stats.Type as unknown as BsnCompetitionTypes) === BsnCompetitionTypes.RELAY
);
// for relay competitions, the gap should be the cumulative gap and not the individual gap
const relevantTotalGap = computed(() => {
    if (isRelay.value) {
        return currentIntermediate?.value?.CumulativeBehind;
    }
    return currentIntermediate?.value?.Tendency;
});
</script>

<style lang="scss"></style>
